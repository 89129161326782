import { createSSRApp, h, defineComponent, markRaw, reactive } from 'vue'
import { createPinia } from 'pinia'
import { setPageContext } from './usePageContext'
import { createGtm } from '@gtm-support/vue-gtm'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import DefaultLayout from '#root/layouts/DefaultLayout.vue'

import '#fonts'
import '#root/assets/styles/base/_scss_root_vars.scss'
import '#root/assets/styles/base/_reset.scss'
import '#root/assets/styles/base/_layout.scss'
import '#root/assets/styles/base/_forms.scss'
import '#root/assets/styles/base/_modal.scss'
import '#root/assets/styles/base/_tailwind.scss'
import '#root/assets/styles/base/_typography.scss'
import '#root/assets/styles/base/_animate.scss'
import '#scss_overrides'

export { createApp }

function createApp(pageContext) {
  const { Page, pageProps, env } = pageContext
  let rootComponent = null
  let appInsights = null
  const PageWithWrapper = defineComponent({
    data: () => ({
      Page: markRaw(Page),
      pageProps: markRaw(pageProps || {}),
      Layout: markRaw(pageContext.exports.Layout || DefaultLayout)
    }),
    created() {
      rootComponent = this
      if (env.APP_INSIGHTS) {
        appInsights = new ApplicationInsights({
          config: {
            connectionString: env.APP_INSIGHTS
          }
        })
        appInsights.loadAppInsights()
        appInsights.trackPageView()
      }
    },
    render() {
      return h(
        this.Layout,
        {},
        {
          default: () => {
            return h(this.Page, this.pageProps)
          }
        }
      )
    }
  })

  const app = createSSRApp(PageWithWrapper)
  const store = createPinia()
  app.config.performance = import.meta.env.DEV
  app.use(store)

  if (env.GOOGLE_TAG_MANAGER_ID) {
    app.use(
      createGtm({
        id: env.GOOGLE_TAG_MANAGER_ID,
        queryParams: {},
        defer: false,
        compatibility: false,
        enabled: false,
        loadScript: true,
        trackOnNextTick: true
      })
    )
  }

  Object.assign(app, {
    changePage: (pageContext) => {
      Object.assign(pageContextReactive, pageContext)
      if (appInsights) {
        appInsights.trackPageView()
      }
      rootComponent.Page = markRaw(pageContext.Page)
      rootComponent.pageProps = markRaw(pageContext.pageProps || {})
      rootComponent.Layout = markRaw(pageContext.exports.Layout || DefaultLayout)
    },
    cancelPageChange: () => {
      rootComponent.Page = markRaw(pageContext.Page)
      window.dispatchEvent(new CustomEvent('exitWarning', { detail: {} }))
      return false
    }
  })

  const pageContextReactive = reactive(pageContext)
  setPageContext(app, pageContextReactive)
  return { app, store }
}

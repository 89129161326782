<!--
  * Component Name: Footer
  * Description: Global footer
  * Props:
      type, content, nofollow
  * Components:
      FooterSocial, FooterContact, FooterCopyright, FooterNav
  * Usage:
      <global-footer
        :type="standard"
        :content="value"
        :scroll-to="false"
        :social="social"
        :nofollow="nofollow"
      />
-->

<template>
  <footer
    v-if="contentData"
    ref="footer"
    class="footer"
    :class="contentData.footerType"
    :content-id="props.contentId"
  >
    <div class="grid-container mx-auto py-10 px-4 lg:py-12 xxl:px-6">
      <HtmlContent
        v-if="contentData.footerHeading && showFooterConts"
        :content="contentData.footerHeading"
        :class="'footer-heading mb-10'"
      />
      <div v-if="contentData.footerType === 'standard'" class="standard-footer-content">
        <FooterNav
          v-if="!contentData.isBasicSite && showFooterConts"
          :content="contentData.footerNavItems"
          :nofollow="nofollow"
        />
        <div v-if="!contentData.isBasicSite" class="footer-contact-container my-10">
          <FooterContact
            v-if="showFooterConts"
            :content="contentData.footerContact"
            :type="contentData.footerType"
          />
          <FooterSocial
            v-if="showFooterConts"
            :social="social"
            :image="contentData.footerContact.footerLogo"
            :nofollow="nofollow"
          />
        </div>
        <FooterCopyright
          v-if="showFooterConts"
          :content="contentData.copyright"
          :type="contentData.footerType"
        />
      </div>
      <div v-else-if="contentData.footerType ? 'focused' : 'funnel'">
        <FooterContact
          v-if="showFooterConts && !contentData.isBasicSite && contentData.footerType === 'focused'"
          :content="contentData.footerContact"
          :type="contentData.footerType"
        />
        <FooterCopyright
          v-if="showFooterConts"
          :content="contentData.copyright"
          :type="contentData.footerType"
          :nofollow="nofollow"
        />
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'
import { useGetContent, useGetContentOptions } from '#root/components/composables/getContent.js'
import { useAsyncCompObserver } from '#root/components/composables/useAsyncObserver.js'

const HtmlContent = defineAsyncComponent(() => import('#root/components/utils/HtmlContent.vue'))
const FooterSocial = defineAsyncComponent(() => import('#root/components/global/footer/FooterSocial.vue'))
const FooterContact = defineAsyncComponent(() => import('#root/components/global/footer/FooterContact.vue'))
const FooterCopyright = defineAsyncComponent(
  () => import('#root/components/global/footer/FooterCopyright.vue')
)
const FooterNav = defineAsyncComponent(() => import('#root/components/global/footer/FooterNav.vue'))

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  nofollow: { type: String, required: false, default: '' },
  contentId: { type: String, required: false, default: '' }
})

const contentData = computed(() => {
  const miscOptions = useGetContentOptions('misc_options.value', [])
  const isBasicSite = miscOptions.filter((option) => option.codename === 'basic_site').length
  return {
    footerNavItems: useGetContent(props.content, 'footer_nav_items.linkedItems', []),
    footerType: useGetContent(props.content, 'footer_type.value[0].codename', 'standard'),
    footerContact: {
      openingTimes: useGetContent(props.content, 'opening_time_copy.value', null),
      footerLogo: useGetContent(props.content, 'brand_logo.value[0]', null),
      footerImageCopy: useGetContent(props.content, 'footer_image_copy.value', null),
      footerImage: useGetContent(props.content, 'footer_image.value[0]', null),
      hidePhone: useGetContent(props.content, 'hide_phone.value[0].codename', false) === 'hide' ? true : false
    },
    copyright: {
      copyrightCopy: useGetContent(props.content, 'copyright_copy.value', null),
      copyrightLinks: useGetContent(props.content, 'copyright_links.linkedItems', []),
      legalCopy: useGetContent(props.content, 'legal_copy.value', null)
    },
    footerHeading: useGetContent(props.content, 'heading.value', null),
    isBasicSite
  }
})

const social = computed(() => {
  return {
    facebook: useGetContentOptions('facebook_url.value', null),
    twitter: useGetContentOptions('twitter_url.value', null),
    youtube: useGetContentOptions('youtube_url.value', null),
    instagram: useGetContentOptions('instagram_url.value', null)
  }
})

let showFooterConts = ref(false)
const { loadComp } = useAsyncCompObserver()
loadComp('.footer', showFooterConts)
</script>

<style lang="scss">
@use 'sass:meta';

.footer {
  background: $footer-bg;

  @if meta.mixin-exists('footer-margin') {
    @include footer-margin();
  }

  .footer-heading {
    h2,
    h3 {
      color: $footer-font;
      margin-bottom: $spacing-10;
    }
  }
}

.global-sticky-menu {
  @include flex-parent(row, nowrap);
  background-color: $cta;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 48px;
  z-index: 998;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.4s;

  @include media-query('phablet') {
    display: none;
  }
}

.global-sticky-menu-item {
  flex: 0 0 33.33%;
  text-align: center;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.two-links {
    flex: 0 0 50%;
  }

  .sticky-link {
    text-decoration: none;
    font-size: $body-small;
    color: $white;
  }

  &:not(:last-child) {
    border-right: 1px solid rgba($white, 0.4);
  }
}

.footer-contact-container {
  display: flex;
  flex-direction: column;

  @include media-query('tablet') {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

footer {
  color: white;
}
</style>

<!--
  * Component Name: GlobalHeader
  * Description: Global header
  * Props:
      content, nofollow
  * Usage:
      <GlobalHeader :content="value" :nofollow="nofollow" />
-->
<template>
  <header
    v-if="content"
    @mouseover="loadSubNav"
    @focus="loadSubNav"
    role="button"
    tabindex="0"
    ref="global-header-container"
    class="global-header-container"
    :class="[contentData.type, contentData.smallHeader]"
    :content-id="props.contentId"
  >
    <ClientOnly>
      <div v-if="cookie" ref="cookie-container-client" class="cookie-container-client">
        <CookieBar v-if="CookieBar" :content="cookie" @resize-elements="resizeElements()" />
      </div>
    </ClientOnly>
    <div
      class="global-header grid-container mx-auto py-4 xxl:py-8 px-4 xxl:px-6"
      :class="contentData.smallHeader"
    >
      <!-- LOGO -->
      <div v-if="contentData.logo" class="logo">
        <div
          @click="logoNavigation"
          @keyup.enter="logoNavigation"
          :rel="nofollow"
          role="button"
          :data-gtm="gtm.homeButtonCTA"
          tabIndex="0"
        >
          <img
            :class="contentData.disableLogoNavigation ? 'no-logo-navigation' : ''"
            :src="optimise(contentData.logo)"
            :alt="contentData.logo?.description || 'Logo'"
            fetchpriority="high"
            loading="eager"
            :width="contentData.logo?.width || '100%'"
            :height="contentData.logo?.height || '100%'"
          />
        </div>
      </div>
      <!-- Navigation -->
      <div class="navigation">
        <div class="ctas">
          <ClientOnly>
            <div class="phone">
              <!-- Callus -->
              <span v-if="contentData.callUsToday && !navigationOptions.hideCallUs" class="callus">
                <!-- Call Campaign -->
                <a
                  v-if="dynamicPhoneNumber && navigationOptions.hideCall"
                  :href="`tel:${dynamicPhoneNumber}`"
                  :data-gtm="gtm.phoneCtaCampaign"
                  aria-label="phone"
                  class="callus-mobile"
                >
                  <PhoneIcon />
                  {{ contentData.callUs }}
                </a>
                <a
                  v-if="contentData.callButton"
                  :href="`tel:${dynamicPhoneNumber}`"
                  aria-label="phone"
                  :data-gtm="!!navigationOptions.hideCallUs ? gtm.phoneCtaCampaign : gtm.phoneCta"
                  class="callus-btn"
                  :class="'m-' + contentData.phoneLinkStyle"
                >
                  <PhoneCtaIcon />
                  <span class="phone-number">{{ dynamicPhoneNumber }}</span>
                </a>
                <span v-else class="callus-desktop">
                  {{ contentData.callUsToday }}
                  <a
                    v-if="dynamicPhoneNumber"
                    :href="`tel:${dynamicPhoneNumber}`"
                    aria-label="phone"
                    :data-gtm="!!navigationOptions.hideCallUs ? gtm.phoneCtaCampaign : gtm.phoneCta"
                  >
                    <span class="phone-number">{{ dynamicPhoneNumber }}</span>
                  </a>
                </span>
              </span>
              <!-- Call Main -->
              <a
                v-if="dynamicPhoneNumber && !navigationOptions.hideCall"
                :href="`tel:${dynamicPhoneNumber}`"
                :data-gtm="gtm.phoneCta"
                aria-label="phone"
                class="call-mobile-container"
              >
                <span v-if="contentData.call" class="call-mobile">
                  <PhoneCtaIcon />
                  <span>{{ contentData.call }}</span>
                </span>
              </a>
            </div>
          </ClientOnly>
          <div v-if="cta && !navigationOptions.hideCta" class="quote">
            <LinkItem :content="cta" :nofollow="nofollow" :gtm="gtm.headerCta" />
          </div>
        </div>
        <nav v-if="!navigationOptions.hideMenu" class="links">
          <div
            class="mobile-menu"
            tabindex="0"
            @click="toggleSubNavState"
            @keyup.enter.prevent="toggleSubNavState"
            role="button"
          >
            <BurgerIcon v-if="!subNavState" />
            <CloseIcon v-else />
            <span v-if="contentData.menu">{{ !subNavState ? contentData.menu : contentData.close }}</span>
          </div>
          <ul class="link-list">
            <li
              v-for="(subNavLinks, i) in contentData.openSubNavigationLinks"
              :key="i"
              :class="{ active: subNavState && activeSubNav === i, 'toggle-link': true }"
            >
              <a
                href="#"
                :data-gtm="gtm.headerNav"
                @click.prevent="(toggleSubNavState(i), setOpenDropdown(i))"
              >
                {{ subNavLinks.elements.title.value }}
              </a>

              <SubNavigation
                v-if="subNavLoaded"
                :content="contentData.subNavigation[i]"
                :nofollow="nofollow"
                :active-sub-nav="activeSubNav"
                :sub-nav-state="subNavState"
                :open-dropdown="openDropdown"
                :index="i"
                :gtm="gtm"
                @set-open-dropdown="setOpenDropdown"
                @toggle-sub-nav-state="toggleSubNavState"
              />
            </li>
            <li v-for="(navLinks, i) in contentData.navigationLinks" :key="i" class="toggle-link">
              <a
                :href="navLinks.elements.url.value"
                :data-gtm="gtm.headerNav"
                :rel="useDetermineRel(navLinks, nofollow)"
                @click="navigateOrScroll(navLinks.elements.url.value, $event)"
                @keyup.enter="navigateOrScroll(navLinks.elements.url.value, $event)"
              >
                {{ navLinks.elements.title.value }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- For Mobile menu -->
    <ClientOnly>
      <MobileNavigation
        v-if="windowSize?.isMobile || windowSize?.isPhablet || windowSize?.isTablet"
        :sub-navigation="contentData.subNavigation"
        :navigation-links="contentData.navigationLinks"
        :nofollow="nofollow"
        :active-sub-nav="activeSubNav"
        :sub-nav-state="subNavState"
        :gtm="gtm"
        @set-open-dropdown="setOpenDropdown"
        @toggle-sub-nav-state="toggleSubNavState"
      />
    </ClientOnly>
  </header>
</template>

<script setup>
import {
  computed,
  onMounted,
  ref,
  watch,
  onUpdated,
  nextTick,
  defineAsyncComponent,
  useTemplateRef
} from 'vue'
import { storeToRefs } from 'pinia'
import { navigate } from 'vike/client/router'
import {
  useGetContent,
  useGetContentOptions,
  useDetermineRel,
  useGetContentGTMOptions
} from '#root/components/composables/getContent.js'
import { useGlobalStore } from '#root/stores/storeGlobal.js'
import { useContentStore } from '#root/stores/storeContent.js'
import { optimise } from '#root/utils/helpers/helperImage.js'
import { scrollToElement, scrollToHash } from '#root/utils/helpers/helperScroll.js'
import { usePageContext } from '#root/renderer/usePageContext'
import { useBrowserLocation } from '@vueuse/core'

import LinkItem from '#root/components/buttons/LinkItem.vue'
import BurgerIcon from '#root/assets/images/icons/burger-icon.svg'
import PhoneIcon from '#root/assets/images/icons/phone-icon.svg'
import PhoneCtaIcon from '#root/assets/images/icons/phone-cta-icon.svg'
import CloseIcon from '#root/assets/images/icons/close-icon.svg'
import ClientOnly from '#root/components/utils/ClientOnly.vue'

const pageContext = usePageContext()
let CookieBar = null
if (!pageContext.env.ONETRUST) {
  CookieBar = defineAsyncComponent(() => import('#root/components/pageblocks/CookieBar.vue'))
}
const MobileNavigation = defineAsyncComponent(() => import('#root/components/global/MobileNavigation.vue'))
const SubNavigation = defineAsyncComponent(() => import('#root/components/global/SubNavigation.vue'))
const globalStore = useGlobalStore()
const contentStore = useContentStore()
const { dynamicCta } = storeToRefs(contentStore)
const { windowSize, dynamicPhoneNumber } = storeToRefs(globalStore)

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  nofollow: { type: String, default: '' },
  isHomepage: { type: Boolean, default: false },
  contentId: { type: String, default: '' },
  cookie: { type: Object, default: null }
})

let subNavLoaded = ref(false)
const loadSubNav = () => {
  if (subNavLoaded.value) return
  subNavLoaded.value = true
}

const contentData = computed(() => {
  const miscOptions = useGetContentOptions('misc_options.value', [])
  const isBasicSite = miscOptions.filter((option) => option.codename === 'basic_site').length
  return {
    logo: useGetContent(props.content, 'logo.value[0]', null),
    callUsToday: useGetContent(props.content, 'call_us_today.value', ''),
    callUs: useGetContent(props.content, 'call_us.value', ''),
    call: useGetContent(props.content, 'call.value', ''),
    phoneLinkStyle: useGetContent(props.content, 'phone_link_style.value[0].codename', false),
    callButton: useGetContent(props.content, 'phone_link_style.value', false).some(
      (e) => e.codename === 'button_style' || 'large'
    )
      ? true
      : false,
    menu: useGetContent(props.content, 'menu.value', ''),
    close: useGetContent(props.content, 'close.value', ''),
    openSubNavigationLinks: useGetContent(props.content, 'open_sub_navigation_links.linkedItems', ''),
    navigationLinks: useGetContent(props.content, 'navigation_links.linkedItems', ''),
    subNavigation: useGetContent(props.content, 'sub_navigation.linkedItems', ''),
    type: useGetContent(props.content, 'type.value[0].codename', ''),
    disableScroll: useGetContent(props.content, 'disable_scroll.value[0].codename', false) === 'yes',
    disableLogoNavigation: !!useGetContent(props.content, 'disable_logo_navigation.value[0]', false),
    smallHeader:
      useGetContent(props.content, 'small_header.value[0].codename', false) === 'yes' ? 'small' : '',
    isBasicSite
  }
})

const cta = computed(() => {
  const cta = useGetContent(props.content, 'cta.linkedItems[0]', false)
  if (dynamicCta.value && cta) {
    cta.elements.site_link.linkedItems[0].elements.url.value = dynamicCta.value
    return cta
  } else if (cta) {
    return cta
  } else {
    return false
  }
})

const subNavState = ref(false)
const openDropdown = ref(false)
const globalHeaderContainer = useTemplateRef('global-header-container')
const cookieContainerClient = useTemplateRef('cookie-container-client')

const logoNavigation = () => {
  const exitWarning = document.querySelector('meta[name="exit-warning"][content]').content === 'enable'
  if (exitWarning) {
    globalStore.toggleModal('warningModal', {
      title: useGetContentOptions('warning_modal_title.value', null),
      content: useGetContentOptions('warning_modal_content.value', null),
      cta1: useGetContentOptions('warning_modal_leave_button.linkedItems[0]', null),
      cta2: useGetContentOptions('warning_modal_stay_button.linkedItems[0]', null),
      logoClick: true
    })
  } else {
    return (contentData.value.isBasicSite && props.isHomepage) || contentData.value.disableLogoNavigation
      ? null
      : navigate('/')
  }
}

const toggleSubNavState = (i) => {
  if (i === activeSubNav.value) {
    subNavState.value = !subNavState.value
    openDropdown.value = false
    return
  } else {
    // Open without animation
    openDropdown.value = true
  }
  if (windowSize.value?.isDesktop && subNavState.value) return
  if (subNavState.value === !subNavState.value) {
    return
  } else {
    subNavState.value = !subNavState.value
  }
  const body = document.querySelector('body')
  if (windowSize.value?.isDesktop) return
  !subNavState.value ? body.classList.remove('overflow-hidden') : body.classList.add('overflow-hidden')
}
const location = useBrowserLocation()
const baseURL = location.value.origin
const url = ref(pageContext.urlPathname)

const navigateOrScroll = (link, event) => {
  const urlLink = new URL(link, baseURL)
  const hash = urlLink.hash
  const path = urlLink.pathname
  if (hash && url.value === path) {
    const element = document.querySelector(hash)
    if (element) {
      event.preventDefault()
      event.stopPropagation()
      scrollToElement(hash, 240)
      return
    }
  } else {
    navigate(link)
  }
}

watch(pageContext, () => {
  url.value = pageContext.urlPathname
  subNavState.value = false
  openDropdown.value = false

  if (globalHeaderContainer) {
    globalHeaderContainer.value?.classList.remove('scroll-down', 'disable-scroll')
  }

  document.querySelector('body').classList.remove('overflow-hidden')
  // watch for disabled scroll
  scrollHeader()
  scrollToHash(pageContext)
})

const activeSubNav = ref(0)

const setOpenDropdown = (i) => {
  if (!windowSize.value?.isDesktop && activeSubNav.value === i) {
    activeSubNav.value = null
  } else {
    activeSubNav.value = i
  }
}

const navigationOptions = computed(() => {
  return {
    hideCta: useGetContent(props.content, 'hide_elements.value', false).some((e) => e.codename === 'cta')
      ? true
      : false,
    hideCall: useGetContent(props.content, 'hide_elements.value', false).some((e) => e.codename === 'phone')
      ? true
      : false,
    hideCallUs: useGetContent(props.content, 'hide_elements.value', false).some(
      (e) => e.codename === 'cta_phone'
    )
      ? true
      : false,
    hideMenu: useGetContent(props.content, 'hide_elements.value', false).some((e) => e.codename === 'menu')
      ? true
      : false
  }
})

const gtm = computed(() => {
  return {
    homeButtonCTA: useGetContentGTMOptions('home_button_cta', ''),
    headerNav: useGetContentGTMOptions('header_nav', ''),
    headerCta: useGetContentGTMOptions('header_get_a_quote_cta', ''),
    subNavProductLink: useGetContentGTMOptions('sub_nav_product_link', ''),
    subNavProductQuoteCTA: useGetContentGTMOptions('sub_nav_product_quote_cta', ''),
    subNavUsefulLinks: useGetContentGTMOptions('sub_nav_useful_links', ''),
    subNavGuideLinks: useGetContentGTMOptions('sub_nav_guide_links', ''),
    phoneCta: useGetContentGTMOptions('main_phone_number_cta', ''),
    phoneCtaCampaign: useGetContentGTMOptions('main_phone_number_cta', '')
  }
})

onMounted(() => {
  document.addEventListener('click', (e) => {
    if (!e.target.closest('.global-header-container')) {
      subNavState.value = false
      openDropdown.value = false
      document.querySelector('body').classList.remove('overflow-hidden')
    }
  })
  scrollHeader()
  nextTick(() => {
    resizeElements()
  })
  window.addEventListener('resize', resizeElements())
})

const scrollHeader = () => {
  if (!globalHeaderContainer) return

  if (contentData.value.disableScroll) {
    globalHeaderContainer.value?.classList.add('disable-scroll')
    return
  }

  const scrollUp = 'scroll-up'
  const scrollDown = 'scroll-down'
  let lastScroll = 0

  window.addEventListener('scroll', () => {
    const currentScroll = window.scrollY

    if (currentScroll <= 0 && globalHeaderContainer.value) {
      globalHeaderContainer.value?.classList.remove(scrollUp)
      globalHeaderContainer.value?.classList.remove(scrollDown)
      return
    }

    if (
      currentScroll > globalHeaderContainer.value?.clientHeight &&
      currentScroll > lastScroll &&
      !globalHeaderContainer.value?.classList.contains(scrollDown)
    ) {
      // down
      subNavState.value = false
      globalHeaderContainer.value?.classList.remove(scrollUp)
      globalHeaderContainer.value?.classList.add(scrollDown)
    } else if (currentScroll < lastScroll && globalHeaderContainer.value?.classList.contains(scrollDown)) {
      // up
      globalHeaderContainer.value?.classList.remove(scrollDown)
      globalHeaderContainer.value?.classList.add(scrollUp)
    }
    lastScroll = currentScroll
  })
}

const resizeElements = () => {
  try {
    const subNav = document.querySelector('.sub-navigation-container')
    const mobNav = document.querySelector('.mobile-nav')
    const body = document.querySelector('body')

    if (!globalHeaderContainer.value) return
    if (cookieContainerClient.value) {
      cookieContainerClient.value.style.height = document.cookie.includes('termsAccepted') ? '0' : '60px'
    }

    const paddingTop = cookieContainerClient.value ? `${cookieContainerClient.value.offsetHeight}px` : '0px'
    const mobNavTop = `${globalHeaderContainer.value.offsetHeight}px`

    globalHeaderContainer.value.style.paddingTop = paddingTop
    if (subNav && !mobNav) subNav.style.paddingTop = paddingTop
    if (mobNav) mobNav.style.top = mobNavTop

    setTimeout(() => {
      body.style.paddingTop = paddingTop
    }, 200)
  } catch (e) {
    console.error(e)
  }
}

onUpdated(() => {
  resizeElements()
})
</script>

<style lang="scss">
.overflow-hidden {
  overflow: hidden;
}

.global-header-container {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  background-color: $white;
  z-index: 9999;
  transition: transform 0.3s ease-in-out;

  &.disable-scroll {
    position: fixed;

    &.scroll-down {
      transform: none;
    }

    &.scroll-up {
      transform: none;
    }
  }

  &.scroll-down {
    transform: translate3d(0, -100%, 0);
  }

  &.scroll-up {
    transform: none;
  }

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    background: $gs-400;
  }
}

.toggle-link {
  a {
    color: $headers;
    text-decoration: none;
  }

  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
  }
}

.global-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  min-height: 88px;
  position: relative;

  .logo img {
    max-width: 100%;
    width: 126px;
    height: auto;
    cursor: pointer;
    display: block;

    &.no-logo-navigation {
      cursor: default;
    }
  }

  @include media-query('desktop') {
    min-height: 129px;
  }

  @include media-query('desktop-large') {
    min-height: 161px;
  }

  &.small {
    padding: $spacing-2 $spacing-4;
    max-height: 64px;
    min-height: 64px;

    .logo img {
      width: 126px;
    }

    @include media-query('desktop') {
      min-height: 96px;
      max-height: 96px;
      padding: $spacing-6;
    }
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  .ctas {
    margin: 0 $spacing-4 0 0;

    .phone {
      .call-mobile {
        display: flex;
        flex-direction: column;
        color: $headers;
        font-size: $body-regular;

        svg {
          margin-top: 3px;
          margin-right: 5px;
        }
      }

      .callus {
        display: block;
        font-weight: $w-body;

        .callus-mobile {
          color: $headers;
          text-decoration: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: $body-small;
          font-weight: $w-display;
          width: 100%;

          svg {
            width: 24px;
            height: 24px;
            margin: 0 8px 0 0;
          }
        }

        .callus-desktop,
        .callus-btn {
          display: none;
        }
      }

      .phone-number {
        display: none;
        font-weight: $w-display;
      }

      a {
        display: flex;
        flex-direction: column;
        font-size: $body-small;
        font-weight: $w-display;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
          overflow: visible;
        }
      }
    }

    .quote {
      display: none;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    color: $headers;

    .mobile-menu {
      display: flex;
      flex-direction: column;
      font-size: $body-small;
      font-weight: $w-display;
      line-height: $body-small;
      justify-content: center;
      align-items: center;
      width: 48px;
      fill: currentColor;

      path {
        fill: currentColor;
      }

      svg {
        width: 24px;
        height: 24px;
        overflow: visible;
      }
    }

    .link-list {
      display: none;
    }
  }
}

.sub-navigation-container {
  position: fixed;
  top: 88px;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: auto;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid $gs-400;
  transition: transform 0.4s cubic-bezier(0, 0.55, 0.45, 1);
  transform: translateX(200%);
  overflow-x: hidden;
  overflow-y: scroll;

  &.animate-in {
    transform: translateX(0);
    visibility: visible;
  }

  &.animate-out {
    visibility: hidden;
    opacity: 0;
    transition: 0s; // route change remove transition
  }

  &.open {
    transition: none !important;
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }

  &:focus-visible {
    outline: none;
  }

  @include media-query('desktop') {
    top: 129px;
    width: 100%;
    max-height: 456px;
    right: 0;
    bottom: 0;
    transform: translateY(-200%);
    overflow: visible;
    transition: transform 0.4s cubic-bezier(0, 0.55, 0.45, 1);
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.1);

    &.animate-in {
      transform: translateY(0);
    }
  }

  @include media-query('desktop-large') {
    top: 161px;
  }
}

// subnav was inherting from nav
.navigation .links .link-list .sub-navigation,
.sub-navigation {
  &:last-child {
    ul {
      margin: 0 0 120px;
    }
  }

  .title {
    padding: $spacing-6;
    border-bottom: 1px solid $gs-400;
    background: $background-accent;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    h5 {
      font-weight: $w-heading;
    }

    svg {
      fill: $brand;
      transform: rotate(270deg);
    }
  }

  .content {
    padding: $spacing-6 $spacing-4 0;

    p {
      font-size: $body-small;
      font-weight: $w-link;
      color: $gs-700;
    }

    .cta-btn {
      margin: 0 0 $spacing-4;
      width: 100%;
      justify-content: space-between;
    }
  }

  ul {
    height: 0;
    padding: $spacing-6 $spacing-4 $spacing-10;

    li {
      &.title {
        display: none;
      }
      margin: 0;
    }

    a {
      padding: 20px 0;
      display: block;
      border-top: 1px solid $gs-400;
      font-size: $body-small;
      line-height: 24px;
      font-weight: $w-display;
      color: $gs-700;
    }

    &.guides-list {
      padding-top: 0;

      .title {
        display: block;
        color: $gs-500;
        font-weight: $w-heading;
        background: none;
        font-size: $body-small;
        line-height: 24px;
        padding: 0 0 16px 0;
        border-bottom: none;

        @include media-query('desktop') {
          padding: 0;
        }
      }
    }
  }

  &.active {
    .title {
      svg {
        transform: rotate(90deg);
      }
    }

    ul {
      height: auto;
    }
  }

  @include media-query('phablet') {
    .content {
      .cta-btn {
        margin: 0 16px 16px 0;
        width: 288px;
      }
    }
  }

  @include media-query('desktop') {
    top: 0;
    left: 0;
    right: 0;
    width: $g-desktop-breakpoint-min-width;
    margin: auto;
    padding: 72px 16px;
    display: none;

    @include media-query('desktop-large') {
      width: $g-desktop-large-breakpoint-min-width;
      padding: 72px 24px;
    }

    &.active {
      display: flex;
    }

    .title {
      display: none;
    }

    .content {
      flex: 1;
      margin: 0;
      padding: 0 16px 0 0;

      .cta-btn {
        max-width: 312px;
      }

      p {
        max-width: 330px;
      }
    }

    ul,
    .sub-navigation-list {
      padding: 0 16px;
      flex: 1;

      li {
        &.title {
          display: block;
          font-weight: $w-display;
          background: transparent;
          border-bottom: 0;
          padding: 0;
          color: $gs-500;
          margin: 0 0 12px;
        }

        &:first-child {
          font-weight: $w-display;
          font-size: $body-small;
          border-top: none;
        }
      }

      a {
        font-size: $body-small;
        border-top: none;
        padding: 0 0 12px;
        margin: 0;

        li {
          margin: 0;
          padding: 0;
        }
      }

      &.guides-list {
        display: block;
      }
    }
  }
}

.sub-links {
  ul {
    a {
      font-weight: $w-display;
      color: $headers;
      text-decoration: none;
      font-size: $body-large;
    }

    li {
      border-bottom: 1px solid $gs-400;
      padding: $spacing-6;
      background: $background-accent;
      display: flex;
      width: 100%;
    }
  }

  @include media-query('desktop') {
    display: none;
  }
}

.menu-accessibliity-close {
  position: absolute;
  top: -9999px;
  right: 20px;

  &:focus-visible {
    top: 20px;
  }
}

.global-header-container.focused {
  .global-header {
    align-items: center;
  }
  .navigation {
    width: 100%;
    align-content: center;
    flex-direction: row-reverse;
    justify-content: space-between;

    .links {
      width: auto;
      padding: 0 0;
    }
  }

  .ctas.phone.call-mobile {
    flex-direction: row;
  }
}

.global-header-container.focused.small {
  .navigation {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;

    @include media-query('desktop') {
      flex-direction: row-reverse;
      justify-content: space-between;

      .links {
        width: auto;
        padding: 0 53px;
      }
    }
  }
}

.global-header-container.funnel {
  .global-header {
    align-items: center;
    justify-content: center;
  }
  .navigation {
    display: none;
  }
}

@include media-query('phablet') {
  .navigation {
    width: 65%;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-content: space-around;

    .ctas {
      margin: 0;
    }

    .links {
      .mobile-menu {
        margin: 0 0 0 16px;
      }
    }
  }
}

@include media-query('tablet') {
  .navigation {
    .ctas {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;

      .quote {
        display: block;
        margin-right: $spacing-6;
      }
    }
  }
}

@include media-query('desktop') {
  .global-header {
    align-items: flex-start;
  }

  .navigation {
    align-content: space-between;
    width: 80%;

    .ctas {
      align-items: center;
      flex-direction: row;

      .phone {
        display: flex;
        flex-wrap: nowrap;

        .call-mobile {
          display: none;
        }

        .callus {
          display: flex;
          margin: 0 8px 0 0;
          font-size: $body-regular;

          .callus-mobile {
            display: none;
          }

          .callus-desktop {
            display: flex;
            font-size: $body-small;
            font-weight: $w-body;
          }

          .callus-btn {
            background-color: $gs-200;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: $spacing-2;
            padding: $spacing-1 $spacing-4;
            height: 40px;
            border-radius: 6px;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            .phone-number {
              color: $core-font;
              font-weight: $w-cta;
              line-height: 120%;
              font-size: $body-large;
            }

            &.m-large {
              background-color: transparent;
              gap: 0;

              .phone-number {
                font-size: 2.8rem;
                font-weight: $w-display;
              }

              svg {
                width: 32px;
                height: 32px;
              }
            }

            svg {
              display: block;
              color: $headers;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }

        .phone-number {
          display: flex;
          color: $headers;
          margin: 0 0 0 8px;
        }

        a {
          font-size: $body-large;
          width: auto;
        }

        svg {
          display: none;
        }
      }

      .quote {
        margin: 0 0 0 16px;
        display: block;
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;

      .link-list {
        display: flex;
        gap: $spacing-10;

        li {
          font-weight: $w-display;
          font-size: $body-small;
          position: relative;
        }

        .toggle-link {
          &.active {
            & > a::after {
              opacity: 1;
              transform: translateY(0);
            }
          }

          & > a {
            display: block;

            &::after {
              content: '';
              position: absolute;
              bottom: -6px;
              left: 0;
              width: 100%;
              height: 3px;
              border-bottom: 6px solid $headers;
              opacity: 0;
              transition: all 0.3s ease-in-out;
              transform: translateY(3px);
            }

            &:hover {
              &::after {
                opacity: 1;
                transform: translateY(0);
              }
            }
          }
        }
      }

      .mobile-menu {
        display: none;
      }
    }
  }

  .navigation .ctas .phone .call-mobile-container {
    display: none;
  }
}

@include media-query('desktop-large') {
  .global-header .logo img {
    width: 251px;
    display: block;
  }
}
</style>

import { onMounted } from 'vue'
import { useContentStore } from '#root/stores/storeContent.js'

export const useAsyncCompObserver = () => {
  const loadComp = (elm, type, aboveForm = false) => {
    const { isCTAScrollTo } = useContentStore()
    onMounted(() => {
      elm = document.querySelector(elm)
      if (isCTAScrollTo && aboveForm) {
        type.value = true
        return true
      } else {
        if (!elm) return null
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                type.value = true
                observer.disconnect()
                return true
              }
            })
          },
          { threshold: 0 }
        )
        observer.observe(elm)
      }
    })
  }

  return { loadComp }
}

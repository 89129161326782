<!--
  * Component Name: LinkItem
  * Description: Navigation item
  * Props:
      content
      showChevron
      to
      gtm
      rightArrow
      leftArrow
      disableScroll
  * Usage:
      <LinkItem :content="value" />
-->
<template>
  <span class="link-element" ref="linkElm" :class="{ animate: animations?.length }">
    <a
      v-if="isExternal || openNewWindow || phoneNumber"
      :href="phoneNumber ? `tel:${url}` : url"
      :class="[addClass, 'm-' + variant, phoneNumber ? 'm-phone' : '']"
      :target="openNewWindow === true ? '_blank' : ''"
      :data-gtm="gtmValue"
      :rel="determineRel"
    >
      <PhoneIcon v-if="phoneIcon" class="icon-phone" />
      {{ linkText }}
      <DownloadIcon v-if="downloadIcon" class="icon-download" />
      <ArrowIcon v-if="variant === 'arrow'" class="icon-right" />
    </a>

    <CtaButton
      v-if="!isExternal && !openNewWindow && !phoneNumber"
      :href="url"
      :class="[addClass, popout ? 'm-' + popout : '']"
      :variant="variant"
      :size="size"
      :shape="shape"
      :gtm="gtmValue"
      :right-arrow="rightArrow"
      :left-arrow="leftArrow"
      :disable-scroll="disableScrollTo"
      :modal="modal"
      :popout="popout"
      :rel="determineRel"
      :anchor="anchor"
      :aria-label="linkText"
    >
      {{ linkText }}
      <slot></slot>
    </CtaButton>
  </span>
</template>

<script setup>
import { computed, onMounted, ref, watch, nextTick } from 'vue'
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '#root/stores/storeGlobal.js'
import { useTrackingStore } from '#root/stores/storeTracking.js'
import {
  useGetContent,
  useDetermineRel,
  useGetContentGTMOptions
} from '#root/components/composables/getContent.js'
import { useAnimate } from '#root/components/composables/useAnimate.js'

import CtaButton from '#root/components/buttons/CtaButton.vue'
import DownloadIcon from '#root/assets/images/icons/download-icon.svg'
import PhoneIcon from '#root/assets/images/icons/phone-icon.svg'
import ArrowIcon from '#root/assets/images/icons/arrow-icon.svg'

const globalStore = useGlobalStore()
const trackingStore = useTrackingStore()
const { dynamicPhoneNumber } = storeToRefs(globalStore)
const { inContentCtaCountersElms } = storeToRefs(trackingStore)
const { setInContentCtaCountersElms } = trackingStore

const props = defineProps({
  variantType: { type: String, required: false, default: '' },
  content: { type: Object, required: false, default: () => {} },
  addClass: { type: String, required: false, default: '' },
  showChevron: { type: String, required: false, default: '' },
  to: { type: String, required: false, default: '' },
  gtm: { type: String, required: false, default: '' },
  rightArrow: { type: Boolean, required: false, default: false },
  leftArrow: { type: Boolean, required: false, default: false },
  disableScroll: { type: Boolean, required: false, default: false },
  nofollow: { type: [String, Boolean], required: false, default: '' },
  anchor: { type: String, required: false, default: '' },
  inContent: { type: Boolean, required: false, default: false }
})

const gtmTypes = {
  quote: useGetContentGTMOptions('in_content_quote_pos'),
  learnMore: useGetContentGTMOptions('in_content_learn_more_pos')
}

const linkElm = ref(null)
const inContentGtm = ref('')
const inContentItem = ref(null)
const inContentGTMType = () => {
  if (linkText.value.toLowerCase().includes('quote') && props.inContent) {
    inContentItem.value = {
      index: linkElm.value.getBoundingClientRect().top,
      elm: linkElm.value,
      key: 'quote'
    }
    setInContentCtaCountersElms({
      ...inContentCtaCountersElms.value,
      quote: [...inContentCtaCountersElms.value.quote, inContentItem].sort(
        (a, b) => a.value.index - b.value.index
      )
    })
  }
  if (linkText.value.toLowerCase().includes('learn more') && props.inContent) {
    inContentItem.value = {
      index: linkElm.value.getBoundingClientRect().top,
      elm: linkElm.value,
      key: 'learnMore'
    }
    setInContentCtaCountersElms({
      ...inContentCtaCountersElms.value,
      learnMore: [...inContentCtaCountersElms.value.learnMore, inContentItem].sort(
        (a, b) => a.value.index - b.value.index
      )
    })
  }
}
const inContentGTMAttr = () => {
  if (!inContentItem.value) return
  const position = inContentCtaCountersElms.value[inContentItem.value.key].findIndex(
    (e) => e.value.index === inContentItem.value.index
  )
  inContentGtm.value = `${gtmTypes[inContentItem.value.key]}=${position + 1}`
}

const gtmValue = computed(() => {
  return useGetContent(props.content, 'data_gtm.value', null) || props.gtm || inContentGtm.value || ''
})

watch(
  () => inContentCtaCountersElms.value,
  (newValue, oldValue) => {
    inContentGTMAttr()
  }
)

onMounted(() => {
  inContentGTMType()
})

const linkText = computed(() => {
  let text = useGetContent(props.content, 'link_text.value', '')
  return text.includes('[[PHONENUMBER]]')
    ? text.replace('[[PHONENUMBER]]', `${dynamicPhoneNumber.value}`)
    : text
})

const popout = computed(() => {
  return useGetContent(props.content, 'popout.value', null)
})

const url = computed(() => {
  const externalLink =
    useGetContent(props.content, 'external_link.value', '').replace(
      '[[PHONENUMBER]]',
      `${dynamicPhoneNumber.value}`
    ) || ''
  const siteLink = useGetContent(props.content, 'site_link.linkedItems[0].elements.url.value', '')
  const to = props.to

  return externalLink || siteLink || to
})

const isExternal = computed(() => {
  return useGetContent(props.content, 'external_link.value', false) ? true : false
})

const openNewWindow = computed(() => {
  return useGetContent(props.content, 'open_in_new_window.value[0].codename', 'no') === 'yes'
})

const phoneNumber = computed(() => {
  return useGetContent(props.content, 'phone_number.value[0].codename', 'no') === 'yes'
})

const size = computed(() => {
  return useGetContent(props.content, 'sizes.value[0].codename', 'normal')
})

const shape = computed(() => {
  return useGetContent(props.content, 'shape.value[0].codename', '')
})

const variant = computed(() => {
  return props.variantType || useGetContent(props.content, 'variant.value[0].codename', 'primary')
})

const downloadIcon = computed(() => {
  const icon = useGetContent(props.content, 'icon.value[0]', null)
  return icon && icon.name === 'Download' && icon.codename === 'yes' ? true : false
})

const phoneIcon = computed(() => {
  const icon = useGetContent(props.content, 'icon.value[0]', null)
  return icon && icon.name === 'Phone' ? true : false
})

const modal = computed(() => {
  return useGetContent(props.content, 'open_modal_window.value[0].codename', null)
})

const determineRel = computed(() => {
  return useDetermineRel(props.content, props.nofollow)
})

const disableScrollTo = computed(() => {
  return (
    props.disableScroll || useGetContent(props.content, 'disable_scroll_to.value[0].codename', 'no') === 'yes'
  )
})

const animations = computed(() => {
  return useGetContent(props.content, 'animations.value', null)
})

const { addAnimationOnView } = useAnimate()

onMounted(() => {
  nextTick(() => {
    if (animations.value?.length) {
      // Select the .cta-btn child from this component only
      const ctaBtn = linkElm.value.querySelector('.cta-btn')
      if (ctaBtn) {
        addAnimationOnView(ctaBtn, animations.value[0].name, 300, 80)
      }
    }
  })
})
</script>

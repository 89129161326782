<!--
  * Component: Button
  * Description: Global CTA Button
  * Props:
      disabled, variant, type, loading, leftArrow, rightArrow
  * Usage:
      <cta-button
        :href=""
        :disabled="false"
        :variant="value"
        :type="value"
        :loading="loading"
        :leftArrow="false"
        :rightArrow="false"
        >
        {{ slotValue }}
      </cta-button>
-->

<template>
  <component
    :is="tag"
    :href="anchor ? `${href}#${anchor}` : navUrl"
    class="cta-btn"
    :class="['m-' + variant, 'm-' + size, 'm-shape-' + shape]"
    :style="`background-color: ${colourSwapVariant ? colourSwapVariant[0] : ''};`"
    :disabled="disabled"
    :type="tag === 'button' ? type : null"
    :loading="loading"
    :left-arrow="leftArrow"
    :right-arrow="rightArrow"
    :modal="modal"
    :data-gtm="gtm"
    @click.prevent="navigateOrScroll($event)"
    @mouseover="colourSwapVariantCheck"
    @focus="colourSwapVariantCheck"
    @mouseleave="colourSwapVariantCheck"
    @blur="colourSwapVariantCheck"
  >
    <ArrowIcon v-if="leftArrow" class="icon-left" />
    <slot></slot>
    <LoadIcon v-if="loading" class="spinner" />
    <DownloadIcon v-if="download" class="icon-download" />
    <ArrowIcon v-if="rightArrow" class="icon-right" />
    <Chevron v-if="chevron" class="chevron-right" />
    <arrow-down v-if="props.popout" class="icon-right icon-popout" />
  </component>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '#root/stores/storeGlobal.js'
import { useContentStore } from '#root/stores/storeContent.js'
import { useVWOStore } from '#root/stores/storeVWO.js'
import { usePageContext } from '#root/renderer/usePageContext'
import { scrollToElement } from '#root/utils/helpers/helperScroll.js'
import { toggle } from '#root/utils/helpers/helperPopout.js'
import { replaceUrlPath, colourSwap } from '#root/utils/helpers/helperVwo.js'
import { navigate } from 'vike/client/router'

import { useGetContentPage } from '#root/components/composables/getContent.js'

import ArrowIcon from '#root/assets/images/icons/arrow-icon.svg'
import LoadIcon from '#root/assets/images/icons/load-icon.svg'
import DownloadIcon from '#root/assets/images/icons/download-icon.svg'
import Chevron from '#root/assets/images/icons/chevron-icon.svg'
import ArrowDown from '#root/assets/images/icons/arrow-down.svg'

const emit = defineEmits(['submit'])
const tag = ref('button')

const props = defineProps({
  disabled: { type: Boolean, default: false },
  variant: { type: String, default: 'primary' },
  type: { type: String, default: 'button' },
  loading: { type: Boolean, default: false },
  leftArrow: { type: Boolean, default: false },
  rightArrow: { type: Boolean, default: false },
  gtm: { type: String, required: false, default: '' },
  chevron: { type: Boolean, default: false },
  download: { type: Boolean, default: false },
  href: { type: String, default: '' },
  size: { type: String, default: 'normal' },
  shape: { type: String, default: 'default' },
  disableScroll: { type: Boolean, default: false },
  modal: { type: Object, default: null },
  popout: { type: String, default: null },
  emitter: { type: Boolean, default: false },
  anchor: { type: String, default: '' }
})

const pageContext = usePageContext()
const globalStore = useGlobalStore()
const contentStore = useContentStore()
const vwoStore = useVWOStore()
const { isCTAScrollTo } = storeToRefs(contentStore)
const { vwoActivatedCampaigns, vwoSettingsFileCampaigns } = storeToRefs(vwoStore)

onMounted(() => {
  buttonType()
})

const navUrl = computed(() => {
  if (props.variant === 'primary') {
    const vwoUrl = replaceUrlPath(vwoActivatedCampaigns, vwoSettingsFileCampaigns, pageContext.urlPathname)
    return vwoUrl ? vwoUrl?.url?.value : props.href
  } else {
    return props.href
  }
})

const colourSwapVariant = computed(() => {
  return props.variant === 'primary'
    ? colourSwap(vwoActivatedCampaigns, vwoSettingsFileCampaigns, pageContext.urlPathname)
    : false
})

const colourSwapVariantCheck = (e) => {
  if (!colourSwapVariant.value) return false
  if (e.type === 'mouseleave') {
    e.target.style.backgroundColor = colourSwapVariant.value[0]
    e.target.style.outline = `none`
    e.target.style.transition = 'all 0.2s linear'
  } else {
    e.target.style.backgroundColor = colourSwapVariant.value[1]
    e.target.style.transition = 'all 0.2s linear'
  }
}

const navigateOrScroll = (event) => {
  if (props.anchor && props.href === pageContext.urlPathname) {
    event.stopPropagation()
    event.preventDefault()
    event.target.blur()
    scrollToElement(`#${props.anchor}`, 175)
    return
  }
  if (colourSwapVariant.value) {
    event.target.style.outline = `4px solid ${colourSwapVariant.value[1]}`
  }
  if (props.popout) {
    toggle(props.popout)
  }
  if (props.modal && props.modal === 'infopack') {
    const modalInfoPackTitle = useGetContentPage('modal_form_infopack_title.value')
    const modalInfoPack = useGetContentPage('modal_form_infopack.linkedItems[0]')
    globalStore.toggleModal('formModal', { title: modalInfoPackTitle, content: modalInfoPack })
    return
  }

  if (isCTAScrollTo.value && !props.disableScroll) {
    scrollToElement('form', 175)
  } else if (navUrl.value && !isCTAScrollTo.value) {
    navigate(navUrl.value)
  } else if (props.emitter) {
    emit('submit')
  }
}

const buttonType = () => {
  if (navUrl.value && (props.disableScroll || !isCTAScrollTo.value)) {
    tag.value = 'a'
  } else {
    tag.value = 'button'
  }
}
</script>

<style lang="scss">
.cta-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 $spacing-6;
  height: 56px;
  border-radius: $btn-radius;
  font-family: $primary-font;
  font-size: $body-small;
  line-height: 3.2rem;
  font-weight: $w-cta;
  text-align: center;
  text-decoration: none;

  svg {
    top: 0.0625rem;
  }

  &:active {
    text-decoration: none;
    outline: 4px solid $primary-cta-click;
  }

  &:hover,
  &:focus {
    box-shadow: $g-box-shadow;
    text-decoration: none;
  }

  .spinner {
    width: 16px;
    height: 16px;
    margin-left: $spacing-2;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .icon-popout {
    path {
      fill: $headers;
    }
  }

  &.m-opened {
    .icon-popout {
      transform: rotate(180deg);
    }
  }

  .icon-download {
    fill: currentColor;
    margin-left: $spacing-2;
    height: 24px;
    width: 24px;
    top: 0;

    path {
      fill: currentColor;
    }
  }

  .icon-phone {
    fill: currentColor;
    margin-right: $spacing-2;
    height: 24px;
    width: 24px;
    top: 0;

    path {
      fill: currentColor;
    }
  }

  .icon-left {
    margin-right: $spacing-1;
    height: 24px;
    width: 24px;
    top: 0;
    transform: rotate(180deg);
  }

  .icon-right {
    margin-left: $spacing-1;
    height: 24px;
    width: 24px;
    top: 0;
  }

  .chevron-right {
    width: 24px;
    top: 0;
    transform: rotate(180deg);
  }

  &:disabled {
    pointer-events: none;
  }

  /*----- sizes -----*/

  &.m-scale {
    @include media-query('desktop') {
      padding: $spacing-6 $spacing-10;
      max-height: 80px;
      height: 80px;
      font-size: $body-large;
      line-height: 3.2rem;
    }
  }

  .m-large {
    font-size: $body-large;
  }

  &.m-large,
  &.m-wide {
    min-width: 213px;
    padding: $spacing-6 $spacing-10;
    max-height: 80px;
    height: 80px;
    font-size: $body-large;
    line-height: 3.2rem;

    .spinner {
      width: 24px;
      height: 24px;
      margin-left: $spacing-4;
    }
  }

  &.m-full,
  &.m-wide,
  &.m-wide_normal {
    min-width: 100%;
  }

  &.m-small {
    height: 40px;
  }

  /*----- shape -----*/

  &.m-shape-rounded {
    border-radius: 50px;
  }

  /*----- button colors -----*/

  &.m-primary {
    color: $white;
    background-color: $primary-cta;

    &:active,
    &:focus {
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.32);
    }

    &:hover {
      background-color: $primary-cta-hover;
      cursor: pointer;
    }

    &:disabled {
      pointer-events: none;
      background: $gs-200;
      color: $gs-500;
    }

    &[loading='true'] {
      pointer-events: none;
      background: $primary-cta-click;
      color: $white;
    }
  }

  &.m-secondary,
  &.m-popout {
    color: $secondary-cta;
    background-color: transparent;
    border: 2px solid $secondary-cta;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      background: $gs-200;
      border: 2px solid $gs-500;
      color: $gs-500;
    }
  }

  &.m-secondary {
    &:active,
    &:focus {
      color: $white;
      background-color: $secondary-cta-click;
      cursor: pointer;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.32);

      svg {
        color: white;
      }
    }

    &:hover {
      color: $white;
      background-color: $secondary-cta-hover;
      cursor: pointer;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.32);

      svg {
        color: white;
      }
    }
  }

  &.m-tertiary,
  &.m-tertiary_arrow {
    color: $tertiary;
    font-size: $body-small;
    line-height: 24px;
    background-color: transparent;
    padding: 0;
    height: auto;
    min-width: 0;

    &:active,
    &:focus {
      color: $tertiary-hover;
    }

    &:focus {
      box-shadow: none;
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
      outline: none;
    }

    &:hover {
      color: $tertiary-hover;
      box-shadow: none;
      transition: color 0.2s linear;
      cursor: pointer;
    }
  }

  &.m-tertiary_arrow {
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M12.0001 20.9895L10.3251 19.2895L15.7501 13.8895H3.7251V11.5395H15.7501L10.3251 6.11445L12.0001 4.43945L20.2751 12.7145L12.0001 20.9895Z' fill='%23535353'/%3E%3C/svg%3E");
    padding-right: $spacing-7;
  }
}

.link-element {
  a.m-arrow {
    font-size: $body-small;
    line-height: 2.4rem;
    font-weight: $w-heading;
    padding-top: 2px;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    svg {
      color: $secondary-cta;
      top: -2px;
    }
  }
}
</style>

// useAnimate.js
import { onUnmounted, nextTick } from 'vue'

export function useAnimate() {
  const addAnimationOnView = (target, animationClass, delay = 0, triggerOffset = 80) => {
    let observer
    const applyAnimation = (entries) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          const element = entry.target
          setTimeout(() => {
            element.classList.add('animate__animated', animationClass)
          }, delay * index)
          observer.unobserve(element)
        }
      })
    }

    const initObserver = () => {
      nextTick(() => {
        // Handle either a CSS selector string or an actual element
        let elements = []
        if (typeof target === 'string') {
          elements = document.querySelectorAll(target)
        } else if (target instanceof Element) {
          elements = [target]
        }

        if (!elements.length) {
          return
        }

        // Intersection Observer config
        const viewportHeight = window.innerHeight
        const rootMargin = `0px 0px -${((100 - triggerOffset) / 100) * viewportHeight}px 0px`

        observer = new IntersectionObserver(applyAnimation, {
          threshold: 0.1,
          rootMargin
        })

        // Observe each element
        elements.forEach((el) => observer.observe(el))
      })
    }

    initObserver()

    // Cleanup
    onUnmounted(() => {
      if (observer) observer.disconnect()
    })
  }

  return { addAnimationOnView }
}
